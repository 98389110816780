<template>
  <div>
    <div class="bus_recomm enterpriseinfoBoxs">
      <!-- <canvas id="myCanvas" width="1920" height="320" style="width: 100%;" /> -->
      <!-- <img src="../../assets/enterprise/bg.png" :height="bannerHeight + 'px'"> -->
      <div class="enterpriseinfoBox">
        <img src="../../assets/enterprise/gord.png" class="gord" />
        <div
          v-for="(v, i) of enterpriseinfoList"
          class="enterpriseinfoBox_i"
          :class="'enterpriseinfoBox_' + i"
          v-if="i < 8"
          @click="$oucy.go('/Shop?enterpriseId=' + v.id)"
          :key="i"
        >
          <img :src="$oucy.ossUrl + v.enterpriseAvata" />
        </div>
      </div>
    </div>
    <div
      :style="{ width: this.$oucy.pageWidth + 'px', display: 'inline-block' }"
    >
      <el-row>
        <el-col :span="5" v-if="$oucy.isICPAudit">
          <div class="left_nav">
            <myMenu
              :list="allGlobalClassifyList"
              :default-active="QueryFurnitureLibrary.identityId"
              @menuSelect="menuSelect"
            >
              <span
                class="bold_span m-b-20"
                style="
                  padding-top: 30px;
                  padding-left: 20px;
                  display: inline-block;
                "
              >
                企业分类
              </span>
            </myMenu>
          </div>
        </el-col>
        <el-col :span="$oucy.isICPAudit ? 19 : 24">
          <div class="top_sort">
            <div class="myauth" v-if="$oucy.isICPAudit">
              <div class="myauthLift">位置</div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 0)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList"
                  @click="setChinaId(v, 0)"
                  :key="i"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <!-- 二层 -->
            <div class="myauth m-t-10" v-if="chinaList1 && chinaList1.length">
              <div class="myauthLift"></div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina1.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 1)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina1.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList1"
                  :key="i"
                  @click="setChinaId(v, 1)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <!-- 三层 -->
            <div class="myauth m-t-10" v-if="chinaList2 && chinaList2.length">
              <div class="myauthLift"></div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina2.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 2)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina2.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList2"
                  :key="i"
                  @click="setChinaId(v, 2)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <!-- 四层 -->
            <div class="myauth m-t-10" v-if="chinaList3 && chinaList3.length">
              <div class="myauthLift"></div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina3.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 3)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina3.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList3"
                  :key="i"
                  @click="setChinaId(v, 3)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <!-- 五层 -->
            <div class="myauth m-t-10" v-if="chinaList4 && chinaList4.length">
              <div class="myauthLift"></div>
              <div class="text-left">
                <button
                  class="mybutton"
                  :class="selectedChina4.chinaId == null ? 'selectedChina' : ''"
                  @click="setChinaId(null, 4)"
                >
                  全部
                </button>
                <button
                  class="mybutton"
                  :class="
                    selectedChina4.chinaId == v.chinaId ? 'selectedChina' : ''
                  "
                  v-for="(v, i) of chinaList4"
                  :key="i"
                  @click="setChinaId(v, 2)"
                >
                  {{ v.chinaName }}
                </button>
              </div>
            </div>
            <div class="auth m-t-30" v-if="$oucy.isICPAudit">
              <span>认证</span>
              <el-radio-group
                v-model="QueryFurnitureLibrary.enterpriseAuthenticationLevel"
                size="small"
                fill="#2090FF"
                @change="queryEnterpriseLibrary"
              >
                <el-radio-button :label="0">全部</el-radio-button>
                <el-radio-button :label="1">实名认证</el-radio-button>
                <el-radio-button :label="2">信守约</el-radio-button>
                <el-radio-button :label="3">品质厂商</el-radio-button>
              </el-radio-group>
            </div>
            <div class="sort">
              <span>排序</span>
              <el-radio-group
                v-model="QueryFurnitureLibrary.enterpriseSort"
                size="small"
                fill="#2090FF"
                @change="queryEnterpriseLibrary"
              >
                <el-radio-button :label="0">全部</el-radio-button>
                <el-radio-button :label="1">最新入驻</el-radio-button>
                <el-radio-button :label="2">最新发布</el-radio-button>
                <el-radio-button :label="3">产品数量</el-radio-button>
                <el-radio-button :label="4">最新登录</el-radio-button>
                <el-radio-button :label="5">关注人气</el-radio-button>
                <el-radio-button :label="6">浏览量</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div>
            <div class="items">
              <NoData
                v-if="!ReturnsObject.content || !ReturnsObject.content.length"
                style="background-color: #fff; margin-top: 10px"
              ></NoData>
              <div
                class="item pointer"
                v-for="(v, i) of ReturnsObject.content"
                :key="v.id"
                @click="gotoEnterprise(v)"
              >
                <img :src="$oucy.ossUrl + v.enterpriseAvata" class="logo" />
                <div class="dot">
                  <div class="df-jc-s-b df-ai-c itemTop">
                    <div class="enterpriseName">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-dianpu6"></use>
                      </svg>
                      {{ v.enterpriseName }}
                      <el-rate
                        v-model="v.enterpriseStar / 2"
                        disabled
                        style="display: inline-block; margin-left: 10px"
                      ></el-rate>
                    </div>
                    <div class="f12 df-ai-c c-3">
                      <el-button
                        @click.stop="queryEnterpriseCustomer(v)"
                        type="text"
                        icon="el-icon-s-comment"
                      >
                        <span class="c-1">商家在线</span>
                      </el-button>
                      <!-- el-icon-star-off -->
                      <el-button
                        type="text"
                        @click.stop="saveCollectUser(v)"
                        :icon="
                          v.isCollect ? 'el-icon-star-on' : 'el-icon-star-off'
                        "
                      >
                        <span class="c-1">收藏</span>
                      </el-button>
                      <!--  <el-button v-if="$oucy.isICPAudit" type="text" icon="el-icon-chat-dot-round"><span class="c-1">微信</span></el-button> -->
                    </div>
                  </div>
                  <div class="c-2 f12 text-left m-b-10">
                    {{ v.enterpriseSignature }}
                  </div>
                  <div class="df-jc-s-b">
                    <div class="text-left">
                      <enterprise-grade :enterprise="v" />
                      <div class="df f12 m-t-10 c-6">
                        <div>
                          关注人数
                          <span>{{ v.enterpriseFansCount }}</span>
                        </div>
                        <div class="m-l-20">
                          产品数量
                          <span>{{ v.enterpriseGoodCount }}</span>
                        </div>
                        <div class="m-l-20">
                          产品更新
                          <span>{{ v.enterpriseGoodUpdatetime }}</span>
                        </div>
                      </div>
                      <div class="m-t-10">
                        <img
                          class="icon4"
                          v-if="
                            v.ltIdentityDto &&
                            v.ltIdentityDto.ltIdentityParentIcon
                          "
                          :src="
                            $oucy.ossUrl + v.ltIdentityDto.ltIdentityParentIcon
                          "
                          style="height: 32px"
                        />
                        <img
                          class="icon4"
                          src="@/assets/images/qiyeku/certification.png"
                          v-if="v.enterpriseCertificationFileDto"
                        />
                        <img
                          class="icon4"
                          src="@/assets/images/qiyeku/deep_icon.png"
                          v-if="0"
                        />
                        <img
                          class="icon4"
                          src="@/assets/images/qiyeku/indeed_icon.png"
                          v-if="0"
                        />
                        <img
                          class="icon4"
                          src="@/assets/images/qiyeku/strength_icon.png"
                          v-if="0"
                        />
                      </div>
                    </div>
                    <div class="m-t-20">
                      <img
                        :src="$oucy.ossUrl + vv.cover"
                        v-for="(vv, ii) of v.spuList"
                        :key="ii"
                        v-if="v.spuList"
                        class="cover"
                      />
                      <!-- <img src="@/assets/images/sofa1.png"> -->
                      <!-- <img src="@/assets/images/sofa1.png"> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              v-if="ReturnsObject.content"
              background
              layout="sizes, prev, pager, next, jumper, ->, total, slot"
              :page-size="ReturnsObject.size"
              :total="ReturnsObject.totalElements"
              @size-change="sizeChange"
              @current-change="currentChange"
              @prev-click="currentChange"
              @next-click="currentChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import {
    enterpriseinfo,
    ltIdentity,
    china,
    collectuser,
  } from "@/service/index.js";
  import { localSet, localGet, localDel } from "@/store/store";
  import oucy from "@/util/oucyUtil";
  import NoData from "../../components/NoData.vue";
  export default {
    name: "Enterprise",
    data() {
      return {
        bannerHeight: 1000,
        allGlobalClassifyList: [],
        // 请求对象
        QueryFurnitureLibrary: {
          category: 1,
          limit: 10,
          start: 0,
          enterpriseAuthenticationLevel: 0,
          enterpriseSort: 0,
          identityId: null,
          regionId: null,
          siteId: null, //    站点
        },
        // 返回对象
        ReturnsObject: {},
        // 位置
        chinaList: [],
        selectedChina: {},
        chinaList1: [],
        selectedChina1: {},
        chinaList2: [],
        selectedChina2: {},
        chinaList3: [],
        selectedChina3: {},
        chinaList4: [],
        selectedChina4: {},
        enterpriseinfoList: [],
        canvasDataURL: null,
        ctx: null,
        location: [
          { x: 629, y: 47, width: 230, height: 113 },
          { x: 864, y: 47, width: 230, height: 113 },
          { x: 1099, y: 47, width: 225, height: 113 },
          { x: 1329, y: 47, width: 225, height: 113 },
          { x: 629, y: 164, width: 259, height: 113 },
          { x: 893, y: 164, width: 259, height: 113 },
          { x: 1157, y: 164, width: 198, height: 113 },
          { x: 1359, y: 164, width: 200, height: 113 },
        ],
      };
    },
    mounted() {
      /*        let canvas = document.getElementById("myCanvas");
          this.ctx = canvas.getContext("2d");
  
          let img = document.getElementById("scream");
  
          img.onload = () => {
              this.ctx.drawImage(img, 0, 0);
          }
          this.ctx.beginPath()
          this.ctx.fillStyle= '#f00'
          this.ctx.moveTo(this.location[0].x, this.location[0].y)
          this.ctx.lineTo(this.location[3].x+this.location[3].width, this.location[3].y)
          this.ctx.lineTo(this.location[7].x+this.location[7].width, this.location[7].y+this.location[7].height)
          this.ctx.lineTo(this.location[4].x, this.location[4].y+this.location[4].height)
          this.ctx.fill()
          this.ctx.closePath()
          canvas.addEventListener('click', (e)=> {
              const canvasInfo = canvas.getBoundingClientRect()
              console.log(canvasInfo)
              console.log(e.clientX)
              console.log(e.clientY)
              this.getClickIndex(e,canvasInfo)
              console.log(this.ctx.isPointInPath(e.clientX - canvasInfo.left, e.clientY - canvasInfo.top))
            })
            */
      let siteId = localGet("siteId");
      if (siteId) {
        this.QueryFurnitureLibrary.siteId = siteId;
      }
      this.setSize();
      window.onresize = () => {
        this.setSize();
      };
      this.findAllLtIdentity();
      this.queryEnterpriseLibrary();
      this.findChina();
      this.getVipEnterprise();
    },
    methods: {
      getClickIndex(e, canvasInfo) {
        let index = null;
        for (let v of this.location) {
          if (v.x + canvasInfo.x < e.clientX && e.clientX < v.height) {
            console.log("有点中的");
            console.log(v);
          }
          if (v.y + canvasInfo.y < e.clientY && e.clientY < v.width) {
            console.log("有点中的");
            console.log(v);
          }
        }
      },
      // 获取首页随机30个VIP企业
      getVipEnterprise() {
        enterpriseinfo.getVipEnterprise().then((res) => {
          this.enterpriseinfoList = res;
          /* let promiseArray = []
                      let len = res.length > 8 ? 8 : res.length
                      for (let i = 0; i < len; i++) {
                          let data = this.download(this.$oucy.ossUrl + res[i].enterpriseAvata)
                          promiseArray.push(data)
                      }
                      Promise.all(promiseArray).then(res => {
                          this.drawImage(res)
                      })*/
        });
      },
      // 画图
      drawImage(promiseArray, ctx) {
        this.ctx.fillStyle = "#FFFFFF";
        for (var i = 0; i < promiseArray.length; i++) {
          let img = promiseArray[i];
          this.ctx.fillRect(
            this.location[i].x,
            this.location[i].y,
            this.location[i].width,
            this.location[i].height
          );
          this.ctx.drawImage(
            img,
            this.location[i].x +
              this.location[i].width / 2 -
              this.location[i].height / 2,
            this.location[i].y,
            this.location[i].height,
            this.location[i].height
          );
          // this.ctx.drawImage(img, this.location[i].x, this.location[i].y, this.location[i].width, this.location[i].height)
        }
      },
      // 下载图片
      download(url) {
        return new Promise(function (resolve, reject) {
          let img = new Image();
          img.src = url;
          // img.setAttribute("crossOrigin",'Anonymous')
          img.onload = function () {
            resolve(img);
          };
          img.onerror = function () {
            reject(error);
          };
        });
      },
      gotoEnterprise(v) {
        // return
        if (v.vipLeve == 2 || v.vipLeve == 1) {
          this.$oucy.openNewPage("/ShopVip?enterpriseId=" + v.id);
        } else {
          this.$oucy.openNewPage("/Shop?enterpriseId=" + v.id);
        }
      },
      setSize: function () {
        // 通过浏览器宽度(图片宽度)计算高度
        this.screenWidth = window.innerWidth;
        this.bannerHeight = (320 / 1920) * this.screenWidth;
      },
      showCity() {
        this.showcity = !this.showcity;
      },
      showProvince() {
        this.showpro = !this.showpro;
      },
      handleClick() {},
      findAllLtIdentity() {
        ltIdentity
          .findAllLtIdentity({ identityIsEnterprise: true })
          .then((res) => {
            this.allGlobalClassifyList = res || [];
            this.allGlobalClassifyList.unshift({
              identityName: "全部企业",
              id: null,
              classifyIconPc: "1c2d07f59a6545649be457986be9d3c6.png",
            });
            this.$oucy.keyAs(this.allGlobalClassifyList, "identityName");
          });
      },
      // 切换菜单回调
      menuSelect(v) {
        console.log(v);
        this.QueryFurnitureLibrary.identityId = v;
        this.queryEnterpriseLibrary();
      },
      // 查询企业列表
      queryEnterpriseLibrary() {
        enterpriseinfo
          .queryEnterpriseLibrary(this.QueryFurnitureLibrary)
          .then((res) => {
            this.ReturnsObject = res || {};
          });
      },
      // 切换页数
      sizeChange: function (pageSize) {
        const me = this;
        me.QueryFurnitureLibrary.limit = pageSize;
        me.queryEnterpriseLibrary();
      },
      // 切换页码
      currentChange: function (current) {
        const me = this;
        me.QueryFurnitureLibrary.start = current - 1;
        me.queryEnterpriseLibrary();
      },
      findChina() {
        china.findChinaTree({ chinaId: null }).then((res) => {
          this.chinaList = res.children;
          /*                chinaList=Array.from(res).map(item => ({
                                          value: item.chinaId,
                                          label: item.chinaName,
                                          leaf: !item.hasChildren
                                      }));*/
        });
      },
      // 点击设置 位置
      setChinaId(v, i) {
        if (i == 0) {
          if (v) {
            this.selectedChina = v;
            if (v.children) {
              this.chinaList1 = v.children;
            } else {
              this.chinaList1 = [];
            }
          } else {
            this.selectedChina = {};
            this.chinaList1 = [];
          }
          this.selectedChina1 = {};
          this.chinaList2 = [];
          this.selectedChina2 = {};
          this.chinaList3 = [];
          this.selectedChina3 = {};
          this.chinaList4 = [];
          this.selectedChina4 = {};
        } else if (i == 1) {
          if (v) {
            this.selectedChina1 = v;
            if (v.children) {
              this.chinaList2 = v.children;
            } else {
              this.chinaList2 = [];
            }
          } else {
            this.selectedChina1 = {};
            this.chinaList2 = [];
          }
          this.selectedChina2 = {};
          this.chinaList3 = [];
          this.selectedChina3 = {};
          this.chinaList4 = [];
          this.selectedChina4 = {};
        } else if (i == 2) {
          if (v) {
            this.selectedChina2 = v;
            if (v.children) {
              this.chinaList3 = v.children;
            } else {
              this.chinaList3 = [];
            }
          } else {
            this.selectedChina2 = {};
          }
          this.chinaList3 = [];
          this.selectedChina3 = {};
          this.chinaList4 = [];
          this.selectedChina4 = {};
        } else if (i == 3) {
          if (v) {
            this.selectedChina3 = v;
            if (v.children) {
              this.chinaList4 = v.children;
            } else {
              this.chinaList4 = [];
            }
          } else {
            this.selectedChina3 = {};
          }
          this.chinaList4 = [];
          this.selectedChina4 = {};
        } else if (i == 4) {
          if (v) {
            this.selectedChina4 = v;
          } else {
            this.selectedChina4 = {};
          }
        }
        if (v) {
          this.QueryFurnitureLibrary.regionId = v.chinaId;
        } else {
          if (this.selectedChina3.chinaId) {
            this.QueryFurnitureLibrary.regionId = this.selectedChina3.chinaId;
          } else if (this.selectedChina2.chinaId) {
            this.QueryFurnitureLibrary.regionId = this.selectedChina2.chinaId;
          } else if (this.selectedChina1.chinaId) {
            this.QueryFurnitureLibrary.regionId = this.selectedChina1.chinaId;
          } else if (this.selectedChina.chinaId) {
            this.QueryFurnitureLibrary.regionId = this.selectedChina.chinaId;
          } else {
            this.QueryFurnitureLibrary.regionId = null;
          }
        }
        this.queryEnterpriseLibrary();
      },
      /**
       * 查询企业客服
       * @param  {[type]} v [description]
       * @return {[type]}   [description]
       */
      queryEnterpriseCustomer(v) {
        enterpriseinfo
          .queryEnterpriseCustomer({ enterpriseId: v.id })
          .then((res) => {
            if (res && res.length) {
              this.openImMessage(res[0].id);
            } else {
              if (v && v.enterpriseAdmins && v.enterpriseAdmins.id) {
                this.openImMessage(v.enterpriseAdmins.id);
              } else {
                this.$alert("商家不在线，请稍后重试！");
              }
            }
          });
      },
      /**
       * 打开聊天框
       * @param  {[type]} targetUserId [description]
       * @return {[type]}              [description]
       */
      openImMessage(targetUserId) {
        console.log("openImMessage");
        this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {
          targetUserId: targetUserId,
        });
        // this.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE,v);
      },
      /**
       * 收藏企业
       * @param  {[type]} v [description]
       * @return {[type]}   [description]
       */
      saveCollectUser(v) {
        collectuser
          .saveCollectUser({ collectType: 1, collectId: v.id })
          .then((res) => {
            v.isCollect = !v.isCollect;
            this.$message("操作成功");
          });
      },
    },
    components: { NoData },
  };
</script>
<style scoped lang="less">
  .bus_recomm {
    width: 100%;
  }

  .left_nav {
    margin-top: 20px;
  }

  .left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
  }

  .el-menu {
    border-right: 0 !important;
    height: 900px;
  }

  .el-menu-item {
    font-size: 13px;
  }

  .nav img {
    margin-right: 8px;
  }

  .nav:hover .icon1 {
    display: none;
  }

  .nav:hover .icon2 {
    display: inline-block;
  }

  .icon2 {
    display: none;
  }

  .bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 30px;*/
  }

  .top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
  }

  .place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
  }

  .place {
    text-align: left;
    margin-bottom: 20px;
    /*margin-left: -170px;*/
    padding-left: 10px;
  }

  .place > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
  }

  .auth {
    margin-bottom: 30px;
    display: flex;
  }

  .auth > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
  }

  .auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
    /*margin-top: 10px;*/
    text-align: left;
  }

  .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  .sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    margin-left: 10px;
  }

  .sort {
    margin-bottom: 30px;
    display: flex;
  }

  .sort > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
  }

  .el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
  }

  .el-radio-button--mini {
    border: none;
  }

  .el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
  }

  .el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  /deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
  }

  .middle {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
  }

  .com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
  }

  .com_head img {
    width: 73px;
    height: 47px;
    margin-top: 15px;
  }

  .middle span {
    width: 160px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    float: left;
    display: flex;
    padding: 25px 0 0 10px;
  }

  .middle_card_star {
    float: left;
    display: flex;
    margin-left: 10px;
  }

  .info {
    width: 70px;
    height: 43px;
    background-color: rgb(248, 248, 249);
    padding: 2px;
    margin: 20px auto 0px;
  }

  .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 6px;
  }

  .goods_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: left;
    margin-left: 7px;
  }

  .pagination {
    margin-top: 50px;
    line-height: 150px;
  }

  .middle ul li:nth-child(3n) {
    margin-right: 0px;
  }

  .middle ul li {
    width: 266px;
    height: 310px;
    background-color: #ffffff;
    padding: 20px;
    float: left;
    margin: 0 50px 10px -40px;
    list-style: none;
  }

  .items {
    margin-left: 10px;
  }

  .item {
    padding: 20px;
    background: #fff;
    margin-top: 10px;
    display: flex;
  }

  .logo {
    width: 168px;
    height: 168px;
    background: #fdfdfd;
    border: 1px solid #f3f3f3;
    border-radius: 1px;
  }

  .dot {
    padding-left: 30px;
    flex: 1;
  }

  .itemTop {
    align-items: flex-start;
    /*margin-bottom: -10px;*/
    vertical-align: middle;
  }

  .itemTop .enterpriseName {
    position: relative;
    top: 8px;
  }

  .icon3 {
    height: 30px;
  }

  .icon4 {
    margin-right: 5px;
  }

  .cover {
    width: 80px;
    height: 80px;
    margin-left: 10px;
  }

  .myauth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #f6f7fb;
    /*margin-left: 10px;*/
    /*margin-top: 10px;*/
    text-align: left;
  }

  .mybutton {
    margin: 0 10px 10px 0;
    background: #f6f7fb;
    border: none;
    color: #606266;
    padding: 6px 12px;
    border-radius: 4px;
  }

  .text-left {
    text-align: left;
  }

  .myauth {
    display: flex;
    text-align: left;
  }

  .myauth .myauthLift {
    text-align: center;
    font-weight: bold;
    display: block;
    width: 50px;
    margin-right: 40px;
  }

  .myauth .text-left {
    flex: 1;
  }

  .selectedChina {
    color: #fff;
    background-color: rgb(32, 144, 255);
  }

  .el-radio-group {
    text-align: left;
  }

  .protectBox {
    position: relative;
  }
  .enterpriseinfoBoxs {
    height: 320px;
    width: 100%;
    min-width: 1280px;
    background-image: url("../../assets/enterprise/bg.png");
    // background-image: url("../../assets/enterprise/whole.png");
    background-size: 100% 100%;
    position: relative;
  }
  .enterpriseinfoBox {
    width: 1280px;
    height: 230px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .gord {
    position: absolute;
    left: 0;
    top: 0;
    width: 270px;
    height: 245px;
  }
  .protectBox_img {
  }

  .enterpriseinfoBox_i {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }

  .enterpriseinfoBox_i img {
    max-width: 100%;
    max-height: 100%;
    // display: none;
  }

  .enterpriseinfoBox_3,
  .enterpriseinfoBox_2,
  .enterpriseinfoBox_1,
  .enterpriseinfoBox_0 {
    position: absolute;
    top: 0px;
    height: 120px;
    width: 245px;
    background: #fff;
  }

  .enterpriseinfoBox_7,
  .enterpriseinfoBox_6,
  .enterpriseinfoBox_5,
  .enterpriseinfoBox_4 {
    position: absolute;
    height: 120px;
    top: 124px;
    background: #fff;
  }

  .enterpriseinfoBox_0 {
    left: 287px;
  }

  .enterpriseinfoBox_1 {
    left: 536px;
  }

  .enterpriseinfoBox_2 {
    left: 785px;
  }

  .enterpriseinfoBox_3 {
    left: 1034px;
  }
  .enterpriseinfoBox_4,
  .enterpriseinfoBox_5 {
    width: 276px;
  }
  .enterpriseinfoBox_4 {
    left: 287px;
  }

  .enterpriseinfoBox_5 {
    left: 567px;
  }

  .enterpriseinfoBox_6,
  .enterpriseinfoBox_7 {
    width: 214px;
  }
  .enterpriseinfoBox_6 {
    left: 847px;
  }

  .enterpriseinfoBox_7 {
    left: 1065px;
  }
</style>
